<template>
    <div>
        <div class="common-layout">
            <el-container style="height:100vh">
                <el-header>
                    <div >
                        <img />
                        <span>金惠码后台管理系统</span>
                    </div>
                    <el-button @click="logout" type="info" style="margin-right: 20px;">退出登录</el-button>
                </el-header>
                <el-container>
                    <el-aside width="200px" style="background-color: #545c64;">
                        <el-row class="tac">
                            <el-col :span="24">
                                <el-menu active-text-color="#ffd04b" background-color="#545c64"
                                    class="el-menu-vertical-demo" default-active="0" text-color="#fff" router>
                                    <el-menu-item index="/OneLevel" >
                                        <el-icon>
                                            <icon-menu />
                                        </el-icon>
                                        <span>一级界面</span>
                                    </el-menu-item>
                                    <el-menu-item index="/Product" >
                                        <el-icon>
                                            <icon-menu />
                                        </el-icon>
                                        <span>二级界面</span>
                                    </el-menu-item>
                                    
                                    <el-menu-item index="/Agency" >
                                        <el-icon>
                                            <icon-menu />
                                        </el-icon>
                                        <span>文章管理</span>
                                    </el-menu-item>
                                    <el-menu-item index="/Msg" >
                                        <el-icon>
                                            <icon-menu />
                                        </el-icon>
                                        <span>查看留言</span>
                                    </el-menu-item>
                                    <el-menu-item index="/Slogans" >
                                        <el-icon>
                                            <icon-menu />
                                        </el-icon>
                                        <span>宣传口号</span>
                                    </el-menu-item>
                                    <el-menu-item index="/Statistics" >
                                        <el-icon>
                                            <icon-menu />
                                        </el-icon>
                                        <span>统计</span>
                                    </el-menu-item>
                                    <el-menu-item index="/ChangePassword" >
                                        <el-icon>
                                            <icon-menu />
                                        </el-icon>
                                        <span>修改密码</span>
                                    </el-menu-item>
                                </el-menu>
                            </el-col>
                        </el-row>
                    </el-aside>
                    <el-container>
                        <el-main>
                            <router-view />
                        </el-main>
                    </el-container>
                </el-container>
            </el-container>
        </div>
    </div>

</template>
<script setup>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import store from '@/store/index'
let router = useRouter();
const logout = () => {
    store.commit("logout")
    router.replace({ path: "/Login" });
}
</script>
<style  scoped>
.el-header {
    background-color: #545C64;
    display: flex;
    justify-content: space-between;
    padding: 0px;
    align-items: center;
    color: #FFFFFF;
    font-size: 20px;
}
.el-header div{
    margin-left: 30px;
}

</style>