import { createStore } from "vuex";

export default createStore({
  state: {
    Token:null,
    userInfo:null
  },
  getters: {},
  mutations: {
    saveToken(state,data){
      if(
        data==null||
        data==""||
        data=="null"||
        data=="undefined"
      ){
        data=null;
      }
      state.Token=data.replace('"','');
      window.sessionStorage.setItem("token",state.Token);
    },
    saveUserInfo(state,data){
      if(
        data==null||
        data==""||
        data=="null"||
        data=="undefined"
      ){
        data=null
      }
      state.userInfo=data;
      window.sessionStorage.setItem("UserInfo",data);
    },
    logout(){
      window.sessionStorage.clear();
    },
  },
  actions: {},
  modules: {},
});
