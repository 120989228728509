import axios from 'axios'
import store from '@/store'
import router from '@/router'
const service = axios.create({
   baseURL:"https://wxapi.jinhuima.top/",
    //baseURL: "http://localhost:5000",
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    },
})
service.interceptors.request.use((config) => {
    config.headers.Authorization = store.state.Token;
    return config
}), (error) => {
    Promise.reject(error)
};
service.interceptors.response.use((response) => {
    return response;
}), (error) => {
    if (error.response) {
        if (error.response.state == 401) {
            router.replace('/Login');
        }
    }
    return Promise.reject(error);
}
export default service