
<template>
  <router-view></router-view>
</template>
<script>
import ButtonCounter from './views/Index.vue'
export default {
  components: {
    ButtonCounter
  }
}
</script>