<template>
    <editor v-model="myValue" :init="init" :disabled="disabled" :id="tinymceId"></editor>
  </template>
  
  
  <script setup >
  //JS部分
  //在js中引入所需的主题和组件
  import tinymce from 'tinymce/tinymce'
  import 'tinymce/skins/content/default/content.css'
  import Editor from '@tinymce/tinymce-vue'
  import 'tinymce/themes/silver'
  import 'tinymce/themes/silver/theme'
  import 'tinymce/icons/default'; //引入编辑器图标icon，不引入则不显示对应图标
  //import 'tinymce/models/dom' // 这里是个坑 一定要引入
  //在TinyMce.vue中接着引入相关插件
  import "tinymce/icons/default/icons"
  import "tinymce/plugins/image" // 插入上传图片插件
  import "tinymce/plugins/media" // 插入视频插件
  import "tinymce/plugins/table" // 插入表格插件
  import "tinymce/plugins/lists" // 列表插件
  import "tinymce/plugins/hr" // 列表插件
  import "tinymce/plugins/wordcount" // 字数统计插件
  import "tinymce/plugins/code" // 源码
  // import "tinymce/plugins/fullscreen" //全屏
  
  //接下来定义编辑器所需要的插件数据
  import { reactive, ref } from "vue"
  import { onMounted, defineEmits, watch } from "@vue/runtime-core"
  import axios from 'axios'
  // import { updateImg } from '@/api/order/order'
  const emits = defineEmits(["getContent"])
  //这里我选择将数据定义在props里面，方便在不同的页面也可以配置出不同的编辑器，当然也可以直接在组件中直接定义
  const props = defineProps({
    value: {
      type: String,
      default: () => {
        return ""
      },
    },
    baseUrl: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default: "hr image media",
    },//必填
    toolbar: {
      type: [String, Array],
      default:
        "fontsizeselect fontselect bold italic underline  forecolor backcolor hr image  media",
    },//必填
  })
  //用于接收外部传递进来的富文本
  const myValue = ref(props.value)  
  const tinymceId = ref("vue-tinymce-" + +new Date() + ((Math.random() * 1000).toFixed(0) + ""))
  //定义一个对象 init初始化
  const init = reactive({
    selector: '#' + tinymceId.value, //富文本编辑器的id,
    language_url: "/tinymce/langs/zh-Hans.js", // 语言包的路径，具体路径看自己的项目，文档后面附上中文js文件
    language: "zh-Hans", //语言
    skin_url: "/tinymce/skins/ui/oxide", // skin路径，具体路径看自己的项目
    height: 400, //编辑器高度
    branding: false, //是否禁用“Powered by TinyMCE”
    menubar: false, //顶部菜单栏显示
    image_dimensions: false, //去除宽高属性
    plugins: props.plugins,  //这里的数据是在props里面就定义好了的
    toolbar: props.toolbar, //这里的数据是在props里面就定义好了的
    font_formats: 'Arial=arial,helvetica,sans-serif; 宋体=SimSun; 微软雅黑=Microsoft Yahei; Impact=impact,chicago;', //字体
    fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 64px 72px', //文字大小
    // paste_convert_word_fake_lists: false, // 插入word文档需要该属性
    //paste_webkit_styles: "all",
    //paste_merge_formats: true,
    //nonbreaking_force_tab: false,
    //paste_auto_cleanup_on_paste: false,
    file_picker_types: 'media',
    media_live_embeds:true,
    content_css: '/tinymce/skins/content/default/content.css', //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入
    //图片上传
    images_upload_handler: (blobInfo, success, failure, progress) => new Promise((resolve, reject) => {
      if (blobInfo.blob().size / 1024 / 1024 > 2) {
        failure('上传失败，图片大小请控制在 2M 以内')
        return
      } else {
        let params = new FormData()
        params.append('file', blobInfo.blob())
        let config = {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
  
          }
        }
        axios.post('https://wxapi.jinhuima.top/Article/UploadImage/1', params).then(res => {
          if (res.data.code == 200) {
            success("https://wxapi.jinhuima.top/jhmimages/" + res.data.url)  //上传成功，在成功函数里填入图片路径
            return
          } else {
            failure('HTTP Error: 上传失败' + res.data.code);
            return
          }
        }).catch(() => {
          failure('上传出错，服务器开小差了呢')
          return
        })
      }
    }),
  
    // 文件上传
    file_picker_callback: (callback, value, meta) => {
     //文件分类
     var filetype='.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
        //后端接收上传文件的地址
        var upurl='https://wxapi.jinhuima.top/Article/UploadImage/2';
        console.log(meta.filetype)
        //为不同插件指定文件类型及后端地址
        switch(meta.filetype){
            case 'image':
                filetype='.jpg, .jpeg, .png, .gif';
                upurl='https://wxapi.jinhuima.top/Article/UploadImage/1'; // 不同的文件类型，设置不同的上传接口
                break;
            case 'media':
                filetype='.mp3, .mp4';
                upurl='https://wxapi.jinhuima.top/Article/UploadImage/2';
                break;
            case 'file':
            default:
        }
        //模拟出一个input用于添加本地文件
        var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', filetype);
        input.click();
        input.onchange = function() {
            var file = this.files[0];
            var xhr, formData;
            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('POST', upurl);
            xhr.onload = function() {
                var json;
                if (xhr.status == 200) {
                  json = JSON.parse(xhr.responseText);
                  callback("https://wxapi.jinhuima.top/jhmimages/"+ json.url);
                  return;
                }else{

                }
            };
            formData = new FormData();
            formData.append('file', file, file.name );
            xhr.send(formData);
        };
      }
  })
  
  //监听外部传递进来的的数据变化
  watch(
    () => props.value,
    () => {
      myValue.value = props.value
      //console.log(props.value)
      emits("getContent", myValue.value)
    },
  )
  //监听富文本中的数据变化
  watch(
    () => myValue.value,
    () => {
      console.log(myValue.value)
      emits("getContent", myValue.value)
    }
  )

  //在onMounted中初始化编辑器
  onMounted(() => {
    tinymce.init({})

  })
  </script>
  
  