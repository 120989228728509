import { createRouter, createWebHashHistory } from "vue-router";
import store from '@/store/index'
import Login from "../views/Login.vue";
import Index from "../views/Index.vue";
import Msg from "../views/Msg.vue";
import Agency from "../views/Agency.vue";
import Product from "../views/Product.vue";
import OneLevel from "../views/OneLevel.vue";
import Statistics from "../views/Statistics.vue";
import ChangePassword from "../views/ChangePassword.vue";
import Slogans from "../views/Slogans.vue";
const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Index,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: '/Msg',
        name: "Msg",
        component: Msg,
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/Agency',
        name: "Agency",
        component: Agency,
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/Product',
        name: "Product",
        component: Product,
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/OneLevel',
        name: "OneLevel",
        component: OneLevel,
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/Statistics',
        name: "Statistics",
        component: Statistics,
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/ChangePassword',
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          requireAuth: true
        },
      },
      {
        path: '/Slogans',
        name: "Slogans",
        component: Slogans,
        meta: {
          requireAuth: true
        },
      }
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
var storeTemp = store;
router.beforeEach((to, from, next) => {
  if (window.sessionStorage.token!=null) {
    storeTemp.commit("saveToken", window.sessionStorage.token);
    storeTemp.commit("saveUserInfo", window.sessionStorage.UserInfo);
  }
  if (to.meta.requireAuth) {
    if (window.sessionStorage.token!=null) {
      next();
    } else {
      next({ path: "/login" })
    }
  } else {
    next();
  }
})
export default router;
