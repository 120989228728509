<template>
  <el-card>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="mName" label="姓名" width="130" />
      <el-table-column prop="mTel" label="手机号码" width="180" />
      <el-table-column prop="createTime" label="提交时间" width="180" />
      <el-table-column prop="mProblem" label="问题" width="480" />
    </el-table>
    <el-pagination layout="prev, pager, next" :page-size="pageSize" :total="count" @current-change="cc" />
  </el-card>

</template>
<script  setup>
import { ref, reactive } from 'vue'
import request from '@/api/http'


const pageNo = ref(1);
const pageSize = ref(20);
const count = ref(0);
const tableData = ref([]);
const getdata = () => {
  request.get('/OnlineMessage/GetOnlineMessageList/' + pageNo.value + '/' + pageSize.value)
    .then((res) => {
      if (res.data.code == 200) {
        tableData.value = res.data.data
        count.value = res.data.count

      }
    }).catch((res) => {
      console.log(res)
    });
}
const cc = (val) => {
  console.log(pageNo.value)
  pageNo.value = val

  getdata();
}
getdata();
</script>