<template>
    <el-card>
        <div style="font-size: 14px;font-weight: 700;">口号列表</div>
        <div style="margin-top: 10px;">
                    <el-button type="primary" @click="ins">添加口号</el-button>
                </div>
        <el-table :data="tableData" border style="width: 100%;margin-top: 10px;">
            <el-table-column prop="title" label="口号" width="400" />
            <el-table-column prop="createTime" label="创建时间" width="155" />
            <el-table-column prop="title" label="操作" width="110">
                <template #default="scope">
                    <el-button :icon="Edit" type="primary" @click="upd(scope.row.id,scope.row.title)"
                        style="width: 30px;height: 30px;"></el-button>
                    <el-button :icon="Delete" type="danger" @click="del(scope.row.id)"
                        style="width: 30px;height: 30px;"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
    <el-dialog v-model="dialogFormVisible" style="width: 427px;" :z-index="100">
        <template #header="{ titleId, titleClass }">
            {{ dialogFormVisibleTitle }}
        </template>
        <el-form :model="form" ref="FormRef" label-position="top">
            <el-form-item label="口号" :label-width="formLabelWidth" prop="title">
                <el-input v-model="form.title" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="dialogFormsubmit">保存</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script  setup>
import { ref, reactive } from 'vue'
import request from '@/api/http'
import { ElMessage, ElMessageBox, FormInstance } from 'element-plus'
import { Delete, Edit, Top, Bottom } from '@element-plus/icons-vue'
const tableData = ref([]);
const updarticleid = ref(0)
const dialogFormVisible = ref(false)
const formLabelWidth = '60px'
const dialogFormVisibleTitle = ref("添加口号")
const form = reactive({
    title: '',
    content: '',
})
const getdata = () => {
    request.get('/Slogans/GetAllSlogans')
        .then((res) => {
            if (res.data.code == 200) {
                tableData.value = res.data.data
                console.log(res.data.data)
            }
        }).catch((res) => {
            console.log(res)
        });
}
const ins = () => {
    dialogFormVisibleTitle.value = "添加口号"
    form.title = ''
    updarticleid.value = 0
    dialogFormVisible.value = true
}
const dialogFormsubmit = async () => {
    if (form.title.trim() == '') {
        ElMessage.error('请填写口号')
        return
    }
    request.post('/Slogans/PostSlogans', {
        Id: updarticleid.value,
        Title: form.title,
    }).then((res) => {
        if (res.data.code == 200) {
            ElMessage({
                message: res.data.msg,
                type: 'success',
            })
            dialogFormVisible.value = false
            updarticleid.value = 0
            getdata();
        } else {
            ElMessage.error(res.data.msg)
        }
    }).catch((res) => {
        console.log(res)
    });
}
const upd = (id,title) => {
    
    dialogFormVisibleTitle.value = "修改口号"
    dialogFormVisible.value = true
    updarticleid.value = id
    form.title = title
}
const del = (id) => {
    ElMessageBox.confirm(
        '确认删除?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            request.post('/Slogans/DelSlogans', {
                Id: id
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        ElMessage({
                            message: res.data.msg,
                            type: 'success',
                        })
                        getdata();
                    }else{
                        ElMessage.error(res.data.msg)
                    }
                }).catch((res) => {
                    console.log(res)
                });
        })
        .catch(() => {

        })

}
getdata();
</script>