
<template >
  <div style="height: 100vh;background-color:#505555 ;">
      <el-row :gutter="24"  style="margin: 0px;">
        <el-col :span="10" :xs="4" :sm="8" :md="9" :lg="10" :xl="10">
          <div class="grid-content ep-bg-purple" />
        </el-col>
        <el-col :span="4" :xs="16" :sm="8" :md="6" :lg="4" :xl="4" style="border:1px black solid;background-color: #ECF0F1;border-radius: 10px;margin-top: 200px;">
          <div style="text-align:center;font-size: 1.6em;margin-bottom: 30px;margin-top: 20px;">登录</div>
          <el-form :rules="loginFromRules" :model="user" ref="ruleFormRef"  @keyup.enter.native="login(ruleFormRef)">
            <el-form-item label="" prop="account">
              <el-input v-model="user.account" placeholder="账号" />
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input v-model="user.password" show-password placeholder="密码" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="login(ruleFormRef)" style="width:100%">登陆</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="10" :xs="4" :sm="8" :md="9" :lg="10" :xl="10">
          <div class="grid-content ep-bg-purple" />
        </el-col>
      </el-row>
  </div>
</template>
<script setup>

import { ref, reactive } from 'vue'
import { ElMessage, ElMessageBox, FormInstance } from 'element-plus'
import { useRouter } from 'vue-router'
import request from '@/api/http'
import store from '@/store/index'
const ruleFormRef = ref(FormInstance)
const loginFromRules = {
  account: [
    { required: true, message: '请输入账号', trigger: 'blur' },
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
  ],
}
let router = useRouter();
const user = ref({
  account: '',
  password: ''
})
const login =async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      request.post('/Users/Login', {
        Account: user.value.account,
        Password: user.value.password
      }).then((res) => {
        if (res.data.code == 200) {
          store.commit("saveToken", JSON.stringify(res.data.data.token))
          store.commit("saveUserInfo", user.value.account)
          ElMessage({
            message: '登录成功',
            type: 'success',
          })
          router.push({ path: "/Statistics" });
        } else {
          ElMessage.error('登陆失败')
        }
      }).catch((res) => {
        console.log(res)
      });
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>
    
<style scoped>
html {
  background-color: #95A5A6;
}
</style>
    