<template>
    <el-row :gutter="24">
        <el-col :span="8">
            <el-card>
                <span style="font-size: 14px;font-weight: 700;">产品列表</span>
                <el-scrollbar height="630px">
                    <div class="custom-tree-container" style="margin-top: 15px;margin-right: 10px;">
                        <el-tree :data="dataSource" show-checkbox node-key="id" default-expand-all
                            :expand-on-click-node="false" @check-change="handleCheckChange">
                            <template #default="{ node, data }">
                                <span class="custom-tree-node" style="width: 100%;">
                                    <span
                                        style="width: 250px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{
                                                node.label
                                        }}</span>
                                    <div style="position: absolute;right: 0;">
                                        <el-button type="primary" plain :icon="Top" size="small" v-if="node.level == 4"
                                            style="height: 20px;" @click="SortUpOrDown(data, 1)" />
                                        <el-button type="primary" plain :icon="Bottom" size="small"
                                            v-if="node.level == 4" style="height: 20px;"
                                            @click="SortUpOrDown(data, 2)" />
                                        <el-button type="danger" :icon="Delete" size="small" v-if="node.level == 4"
                                            style="height: 20px;" @click="delnav(data)" />
                                        <el-button type="info" size="small"
                                            v-if="node.level == 2 && data.organArticleId != null" style="height: 20px;">
                                            服务机构文章ID:{{
                                                    data.organArticleId
                                            }}</el-button>
                                        <el-button type="primary" size="small"
                                            v-if="node.level == 3 && data.policyArticleId != null"
                                            style="height: 20px;">政策概览文章ID:{{
                                                    data.policyArticleId
                                            }}</el-button>

                                    </div>

                                </span>
                            </template>
                        </el-tree>
                    </div>
                </el-scrollbar>
            </el-card>
        </el-col>
        <el-col :span="16">
            <el-card>
                <div style="font-size: 14px;font-weight: 700;">文章列表</div>
                <div style="margin-top: 10px;">
                    <el-button type="primary" @click="ins">添加文章</el-button>
                    <el-button type="success" @click="menuarticle">保存分配为文章</el-button>
                    <el-button type="success" @click="menufwjg">保存分配为服务机构</el-button>
                    <!-- <el-button type="success" @click="menuzcgl">保存分配为政策概览</el-button>-->
                </div>

                <el-table :data="tableData" border style="width: 100%;margin-top: 15px;" highlight-current-row
                    height="540px" @current-change="handleCurrentChange" @select="selectionclick"
                    @select-all="selectionallclick">
                    <el-table-column type="selection" width="39" />
                    <el-table-column prop="id" label="ID" width="65" />
                    <el-table-column prop="title" label="标题" width="280" />
                    <el-table-column prop="createTime" label="提交时间" width="180" />
                    <el-table-column prop="liulan" label="总浏览量" width="85" />
                    <el-table-column prop="zan" label="总点赞数" width="85" />
                    <el-table-column label="操作" width="101">
                        <template #default="scope">
                            <!-- <el-button :icon="Search" type="primary" @click="sel(scope.row.id)"
                                style="width: 30px;height: 30px;"></el-button>-->
                            <el-button :icon="Edit" type="primary" @click="upd(scope.row.id)"
                                style="width: 30px;height: 30px;"></el-button>
                            <el-button :icon="Delete" type="danger" @click="del(scope.row.id)"
                                style="width: 30px;height: 30px;"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next,jumper" :page-size="pageSize" :total="count" @current-change="cc" />
            </el-card>
        </el-col>
    </el-row>
    <el-dialog v-model="dialogFormVisible" style="width: 427px;" :z-index="100">
        <template #header="{ titleId, titleClass }">
            {{ dialogFormVisibleTitle }}
        </template>
        <el-form :model="form" ref="FormRef" label-position="top">
            <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
                <el-input v-model="form.title" autocomplete="off" />
            </el-form-item>
            <el-form-item label="内容" :label-width="formLabelWidth">
                <TEditor ref="editor" v-model="formState.content" :disabled='false' @getContent="getContent" />
                <!--<textarea id="mytextarea" ref="editor" style="min-height: 300px;"></textarea>-->
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="dialogFormsubmit">保存</el-button>
            </span>
        </template>
    </el-dialog>
</template>
  
<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox, FormInstance } from 'element-plus'
import { Delete, Edit, Top, Bottom } from '@element-plus/icons-vue'
import request from '@/api/http'
import TEditor from '@/components/editor.vue';
//let id = 1000
const pageNo = ref(1);
const pageSize = ref(10);
const count = ref(0);
const tableData = ref([]);
const dataSource = ref([])
const dialogFormVisible = ref(false)
const dialogFormVisibleTitle = ref("添加文章")
const formLabelWidth = '60px'
const updarticleid = ref(0)
const FormRef = FormInstance
const editor = ref(null)
const menulist = []
const articlelist = []
const fwjg = []
const form = reactive({
    title: '',
    content: '',
})
let shouci=true
//下面两行是解决tinymce第一次显示时填写富文本失败的代码，别删除！！
dialogFormVisible.value = true
dialogFormVisible.value = false


const formState = reactive({ contents: '' })
const getContent = (v) => {
    console.log(v)
    formState.contents = v
}
const GetActionList = () => {
    request.get('/Article/GetActionList/' + pageNo.value + '/' + pageSize.value)
        .then((res) => {
            if (res.data.code == 200) {
                tableData.value = res.data.data
                count.value = res.data.count
            } else if (res.data.code == 500) {
                ElMessage.error('服务器发生异常')
            }
        }).catch((res) => {
            console.log(res)
        });
}
const GetNavArtAllList = () => {
    request.get('/Navigation/GetNavArtAllList')
        .then((res) => {
            if (res.data.code == 200) {
                dataSource.value = res.data.data
                console.log(res.data.data)
            } else if (res.data.code == 500) {
                ElMessage.error('服务器发生异常')
            }
        }).catch((res) => {
            console.log(res)
        });
}
const selectionallclick = (selection) => {
    articlelist.length = 0
    selection.forEach(element => {
        articlelist.push(element.id)
    });
}
const menufwjg = () => {
    if (articlelist.length != 1) {
        ElMessage.error('请选择一个文章')
        return
    }
    if (fwjg.length == 0) {
        ElMessage.error('请选择一个机构')
        return
    }
    ElMessageBox.confirm(
        '是否把ID:' + articlelist[0] + '的文章设置为服务机构?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            request.post('/Navigation/UpdOrgan', {
                articleid: articlelist[0],
                navid: fwjg.toString()
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        ElMessage({
                            message: res.data.msg,
                            type: 'success',
                        })
                        GetNavArtAllList()
                    }
                }).catch((res) => {
                    console.log(res)
                });
        })
        .catch(() => {

        })
}
const menuzcgl = () => {
    if (articlelist.length != 1) {
        ElMessage.error('请选择一个文章')
        return
    }
    if (menulist.length == 0) {
        ElMessage.error('请选择一个产品')
        return
    }
    ElMessageBox.confirm(
        '是否把ID:' + articlelist[0] + '的文章设置为政策概览?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            request.post('/Navigation/UpdPolicy', {
                articleid: articlelist[0],
                navid: menulist.toString()
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        ElMessage({
                            message: res.data.msg,
                            type: 'success',
                        })
                        GetNavArtAllList()
                    }
                }).catch((res) => {
                    console.log(res)
                });
        })
        .catch(() => {

        })
}
const cc = (val) => {
    pageNo.value = val
    GetActionList();
}
const handleCheckChange = (data, checked, indeterminate) => {
    if (data.level == 3) {
        if (checked) {
            menulist.push(data.id)
        } else {
            menulist.splice(menulist.indexOf(data.id), 1)
        }
    }
    if (data.level == 2) {
        if (checked) {
            fwjg.push(data.id)
        } else {
            fwjg.splice(fwjg.indexOf(data.id), 1)
        }
    }
    //console.log(data, checked, indeterminate)
}
const SortUpOrDown = (data, type) => {
    request.get('/NavArt/SortUpOrDown/' + data.id + '/' + data.parent + '/' + type)
        .then((res) => {
            if (res.data.code == 200) {
                ElMessage({
                    message: res.data.msg,
                    type: 'success',
                })
                GetNavArtAllList();
            } else {
                ElMessage.error(res.data.msg)
            }
        }).catch((res) => {
            console.log(res)
        });
}
GetActionList();
GetNavArtAllList();
const handleCurrentChange = (val) => {
    console.log(val)

}
const selectionclick = (selection, row) => {
    articlelist.length = 0
    selection.forEach(element => {
        articlelist.push(element.id)
    });
}
const dialogFormsubmit = async () => {
    if (form.title.trim() == '') {
        ElMessage.error('请填写标题')
        return
    }
    request.post('/Article/Insert', {
        Id: updarticleid.value,
        Title: form.title,
        Contents:  tinymce.activeEditor.getContent()
    }).then((res) => {
        if (res.data.code == 200) {
            ElMessage({
                message: '操作成功',
                type: 'success',
            })
            dialogFormVisible.value = false
            updarticleid.value = 0
            GetNavArtAllList()
            GetActionList()
        } else {
            ElMessage.error('操作失败')
        }
    }).catch((res) => {
        console.log(res)
    });
}
const ins = () => {
    shouci=false
    dialogFormVisibleTitle.value = "添加文章"
    form.title = ''
    updarticleid.value = 0
    dialogFormVisible.value = true
    tinymce.activeEditor.setContent('')
}
const upd = (id) => {
    
    dialogFormVisibleTitle.value = "修改文章"
    dialogFormVisible.value = true
    updarticleid.value = id
    request.get('/Article/GetArticleById/' + id + '/2')
        .then((res) => {
            if (res.data.code == 200) {
                form.title = res.data.data.title
                if(shouci){
                    setTimeout(() => {
                    tinymce.activeEditor.setContent(res.data.data.contents)
                }, 200);
                }else{
                    tinymce.activeEditor.setContent(res.data.data.contents)
                }
                shouci=false
            }
        }).catch((res) => {
            console.log(res)
        });
}
const del = (id) => {
    ElMessageBox.confirm(
        '所分配的文章也会被删除，是否删除?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            request.post('/Article/DelArticle', {
                articleId: id
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        ElMessage({
                            message: res.data.msg,
                            type: 'success',
                        })
                        GetActionList()
                        GetNavArtAllList()
                    }
                }).catch((res) => {
                    console.log(res)
                });
        })
        .catch(() => {

        })

}
const menuarticle = () => {
    request.post('/NavArt/Insert', {
        articleid: articlelist.toString(),
        navid: menulist.toString()
    })
        .then((res) => {
            if (res.data.code == 200) {
                ElMessage({
                    message: res.data.msg,
                    type: 'success',
                })
                GetNavArtAllList()
            }
        }).catch((res) => {
            console.log(res)
        });
}
const delnav = (data) => {
    ElMessageBox.confirm(
        '是否删除?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        })
        .then(() => {
            request.post('/NavArt/Delete', {
                articleid: data.id,
                navid: data.parent
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        ElMessage({
                            message: res.data.msg,
                            type: 'success',
                        })
                        GetNavArtAllList()
                    } else {
                        ElMessage.error('删除失败，服务器错误')

                    }
                }).catch((res) => {
                    console.log(res)
                });
        })
        .catch(() => {

        })
}
</script>
  
<style scoped  :v-deep lang="less">
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.el-dialog__body {
    padding-top: 0px;
}
.tox-tinymce-aux{
    z-index: 3000;
}
</style>
