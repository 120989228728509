<template>
    <el-space wrap>
        <el-card class="box-card" style="width: 160px;">
            总点赞数(个)<br/>
            <div style="font-size: 30px;">{{zdzs}}</div>
            
        </el-card>
        <el-card class="box-card" style="width: 160px;">
            今日点赞数(个)<br/>
            <div style="font-size: 30px;">{{jrdzs}}</div>
        </el-card>
        <el-card class="box-card" style="width: 160px;">
            总访问量(次)<br/>
            <div style="font-size: 30px;">{{zfwl}}</div>
        </el-card>
        <el-card class="box-card" style="width: 160px;">
            今日访问量(次)<br/>
            <div style="font-size: 30px;">{{jrfwl}}</div>
        </el-card>
    </el-space>
</template>
<script setup>
import request from '@/api/http'
import { ref } from 'vue'
const zdzs=ref(0)
const jrdzs=ref(0)
const zfwl=ref(0)
const jrfwl=ref(0)
const Statistics=()=>{
    request.get('/Article/Statistics')
        .then((res) => {
            if (res.data.code == 200) {
                zdzs.value=res.data.likes
                jrdzs.value=res.data.jinrilikes
                zfwl.value=res.data.pageView
                jrfwl.value=res.data.jinripageView
            } else if (res.data.code == 500) {
                ElMessage.error('服务器发生异常')
            }
        }).catch((res) => {
            console.log(res)
        });

}
Statistics()
setInterval(function() {
    Statistics()
},10000)
</script>