<template>
    <el-card>
        <el-cascader v-model="value" :options="options" :props="props" @change="ccc" placeholder="选择类目"
            ref="cascader" />
        <el-button type="primary" style="margin-left: 10px;" @click="InsProduct" >添加机构</el-button>
        <el-table :data="tableData" border style="width: 100%;margin-top: 10px;">
            <el-table-column prop="navigationName" label="机构名称" width="200">
                <template #default="scope">
                    <div style="display: flex;">
                        <span style="margin-left: 10px">{{ scope.row.navigationName }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="101">
                <template #default="scope">
                    <el-button :icon="Edit" type="primary" @click="upd(scope.row.id, scope.row.navigationName)"
                        style="width: 30px;height: 30px;">
                    </el-button>
                    <el-button :icon="Delete" type="danger" @click="del(scope.row.id, scope.row.navigationName)"
                        style="width: 30px;height: 30px;"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
    <el-dialog v-model="dialogFormVisible" style="width: 400px;" :close-on-click-modal="false"
        :close-on-press-escape="false" :before-close="BeforeClose">
        <template #header="{ titleId, titleClass }">
            {{ dialogFormVisibleTitle }}
        </template>
        <el-form :model="form" ref="FormRef" label-position="top" :rules="rules">
            <el-form-item :label="label1" prop="proName">
                <el-input v-model="form.proName" autocomplete="off" />
            </el-form-item>
            <el-form-item :label="label2" prop="proImgUrl" >
                <el-upload action="https://wxapi.jinhuima.top/Navigation/UploadImage" ref="uploadRef1"
                    :class="{ disable1: uploadDisabled1() }" :on-change="handleChange1" :auto-upload="false"
                    list-type="picture-card" :limit="1" v-model:file-list="fileList1" :on-success="uploadsuccess1"
                    :data={parentId:uploadData,imgType:0}>
                    <el-icon>
                        <Plus />
                    </el-icon>

                    <template #file="{ file }">
                        <div>
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleRemove1(file)">
                                    <el-icon>
                                        <Delete />
                                    </el-icon>
                                </span>
                            </span>
                        </div>
                    </template>
                </el-upload>
            </el-form-item>
            <el-form-item :label="label3" prop="proImgUrlHeader" >
                <el-upload action="https://wxapi.jinhuima.top/Navigation/UploadImage" ref="uploadRef2"
                    :class="{ disable2: uploadDisabled2() }" :on-change="handleChange2" :auto-upload="false"
                    list-type="picture-card" :limit="1" v-model:file-list="fileList2" :on-success="uploadsuccess2"
                    :data={parentId:uploadData,imgType:4}>
                    <el-icon>
                        <Plus />
                    </el-icon>

                    <template #file="{ file }">
                        <div>
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleRemove2(file)">
                                    <el-icon>
                                        <Delete />
                                    </el-icon>
                                </span>
                            </span>
                        </div>
                    </template>
                </el-upload>
            </el-form-item>
            <div style="clear: none;"></div>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="dialogFormsubmit(FormRef)">保存</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script  setup>
import { ref, reactive } from 'vue'
import { Delete, Edit, UploadFilled } from '@element-plus/icons-vue'
import request from '@/api/http'
import { ElMessage, ElMessageBox, FormInstance, UploadInstance, FormRules } from 'element-plus'
const value = ref([])
const FormRef = ref(FormInstance)
const dialogFormVisible = ref(false)
const dialogFormVisibleTitle = ref('')
const fileList1 = ref([])
const fileList2 = ref([])

let imgType=0
let uploadImgCode1=0
let uploadImgCode2=0
let uploadId = 0
let img1Code = {
    code: '',
    url: ''
}
let img2Code = {
    code: '',
    url: ''
}
const label1=ref("银行/保险机构名称(一级页面图标下边的名称)")
const label2=ref("一级页按钮图片(宽170px高75px)")
const label3=ref("二级页头部图片(宽1080px高167px)")
const props = {
    expandTrigger: 'hover',
}
const uploadData = ref(0)
const form = reactive({
    proName: '',
    proImgUrl: '',
    proImgUrlHeader: '',
})
const uploadRef1 = ref()
const uploadRef2 = ref()
const options = ref([])
const tableData = ref([]);
const rules = reactive({
    proName: [
        { required: true, message: '请填写银行/保险机构名称', trigger: 'blur' },
    ],
    proImgUrl: [
        { required: true, message: '请上传一级页按钮图片', trigger: 'blur' },
    ],
    proImgUrlHeader:[
    { required: true, message: '请上传二级页头部图片', trigger: 'blur' },
    ]
})
const ccc = (value) => {
    uploadData.value = value[0]
    imgType=value[0]
    console.log(value[0])
    //宣传页的id是1018
    if(value[0]==1018){
        label1.value="宣传页名称(小程序上不会显示这个名称)"
        label2.value="一级页按钮图片(宽376px高160px)"
        label3.value="二级页头部图片(宽376px高150px)"
    }else{

        label1.value="银行/保险机构名称(一级页面图标下边的名称)"
        label2.value="一级页按钮图片(宽170px高75px)"
        label3.value="二级页头部图片(宽1080px高167px)"
    }
    getdata(value[0])
}


function uploadDisabled1() {
    return form.proImgUrl != ''
}
function uploadDisabled2() {
    return form.proImgUrlHeader != ''
}
const handleChange1 = (file, fileList) => {
    console.info(fileList)
    if (fileList.length >= 1) {
        uploadImgCode1=1
        form.proImgUrl = '1'
    } else {
        form.proImgUrl = ''
    }
}
const handleChange2 = (file, fileList) => {
    console.info(fileList)
    if (fileList.length >= 1) {
        uploadImgCode2=1
        form.proImgUrlHeader = '1'
    } else {
        form.proImgUrlHeader = ''
    }
}
const uploadsuccess1 = (UploadFile, UploadFiles) => {
    img1Code.code = UploadFile.code
    img1Code.url = UploadFile.url
}
const uploadsuccess2 = (UploadFile, UploadFiles) => {
    img2Code.code = UploadFile.code
    img2Code.url = UploadFile.url
}
const getdata = (parentId) => {
    request.get('/Navigation/GetNavigationByIdOneLevel/' + parentId)
        .then((res) => {
            if (res.data.code == 200) {
                tableData.value = res.data.data
            }
        }).catch((res) => {
            console.log(res)
        });
}
const handleRemove1 = (file) => {
    fileList1.value = []
    uploadImgCode1=0
    form.proImgUrl = ''
}
const handleRemove2 = (file) => {
    fileList2.value = []
    uploadImgCode2=0
    form.proImgUrlHeader = ''
}
const del = (id, navName) => {
    ElMessageBox.confirm(
        `是否删除${navName}(不会删除该机构下的文章)`,
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            request.post('/Navigation/DelNav', id)
                .then((res) => {
                    if (res.data.code == 200) {
                        ElMessage({
                            message: res.data.msg,
                            type: 'success',
                        })
                        getdata(uploadData.value)
                    } else {
                        ElMessage.error(res.data.msg)
                    }
                }).catch((res) => {
                    console.log(res)
                });
        })
        .catch(() => {

        })

}
const dialogFormsubmit = async (formEl) => {
    if (!formEl) {
        return;
    }
    await formEl.validate((valid, fields) => {
        if (valid) {
            if (uploadId != 0) {
                UpdateDB()
            } else {
                InsertDB()
            }

        } else {
            //console.log('表单空了', fields)
        }

    })

}
const UpdateDB = () => {
    let timer = null;
    let a = 0
    if(uploadImgCode1 == '1'){
        uploadRef1.value.submit()
    }
    if(uploadImgCode2 == '1'){
        uploadRef2.value.submit()
    }
    timer = setInterval(() => {
        //修改了第一个图
        if(uploadImgCode1 == '1'&&uploadImgCode2 != '1'){
        if (img1Code.code == 200) {
            UpdateSubmit()
            clearInterval(timer)
        }
    }
    if(uploadImgCode1 != '1'&&uploadImgCode2 == '1'){
        if (img2Code.code == 200) {
            UpdateSubmit()
            clearInterval(timer)
        }
    }
    if(uploadImgCode1 == '1'&&uploadImgCode2 == '1'){
        if (img1Code.code == 200&&img2Code.code == 200) {
            UpdateSubmit()
            clearInterval(timer)
        }
    }else{
            UpdateSubmit()
            clearInterval(timer)
        }
    }, 500)
            

}
const UpdateSubmit=()=>{
    request.post('/Navigation/UpdateNavOneLevel/'+uploadId, {
                navName: form.proName,
                iconImgUrl: img1Code.url,
                headerImgUrl:img2Code.url
            }).then((res) => {
                if (res.data.code == 200) {
                    ElMessage({
                        message: res.data.msg,
                        type: 'success',
                    })
                    dialogFormVisible.value = false
                    DataCloer()
                    getdata(uploadData.value)
                } else {
                    ElMessage.error(res.data.msg)
                }
            }).catch((res) => {
                console.log(res)
            });
}
const InsertDB = () => {
    let timer = null;
    let a = 0
    uploadRef1.value.submit()
    uploadRef2.value.submit()
    timer = setInterval(() => {
        a++
        console.log(a)
        if (img1Code.code == 200&&img2Code.code==200) {
            clearInterval(timer)
            timer = null;
            ProductSubmit()
        }
        if (a == 20) {
            clearInterval(timer)
            ElMessage({
                message: '上传图片异常',
                type: 'warning',
            })
        }
    }, 500);
}
const ProductSubmit = () => {
    request.post('/Navigation/NavSubmitOneLevel', {
        navName: form.proName,
        iconImgUrl: img1Code.url,
        headerImgUrl: img2Code.url,
        parentId: uploadData.value
    }).then((res) => {
        if (res.data.code == 200) {
            ElMessage({
                message: res.data.msg,
                type: 'success',
            })
            dialogFormVisible.value = false
            DataCloer()
            
            getdata(uploadData.value)
        } else {
            ElMessage.error(res.data.msg)
        }
    }).catch((res) => {
        console.log(res)
    });
}
const getSelectData = () => {
    request.get('/Navigation/GetNavOneLevel')
        .then((res) => {
            if (res.data.code == 200) {
                options.value = res.data.data
            }
        }).catch((res) => {
            console.log(res)
        });
}
const InsProduct = () => {
    if (value.value.length == 0) {
        ElMessage({
            message: '先在左边选个类目，然后再添加机构',
            type: 'warning',
        })
        return
    }
    form.proName = ''
    dialogFormVisible.value = true
    dialogFormVisibleTitle.value = '添加机构'
}
const upd = (id, name) => {
    uploadId = id
    dialogFormVisible.value = true
    dialogFormVisibleTitle.value = `修改【${name}】`
    request.get('/Navigation/GetNavigationById/' + id)
        .then((res) => {
            if (res.data.code == 200) {
                form.proName = res.data.data.navigationName
                fileList1.value = [{
                    name: 'figure-2.png',
                    url: res.data.data.navigationImage,
                }]
                fileList2.value = [{
                    name: 'figure-22.png',
                    url: res.data.data.navigationHeaderImage,
                }]
                form.proImgUrl = '1'
                form.proImgUrlHeader='1'
            }
        }).catch((res) => {
            console.log(res)
        });
}
getSelectData()
const BeforeClose = (done) => {
    //关闭前清空所有值
    DataCloer()
    done()
}
const DataCloer=()=>{
    dialogFormVisibleTitle.value = ''
    img1Code.code = 0
    img1Code.url = ''
    img2Code.code = 0
    img2Code.url = ''
    fileList1.value = []
    fileList2.value=[]
    form.proImgUrl = ''
    form.proName = ''
    form.proImgUrlHeader=''
    uploadRef1.value = ''
    uploadRef2.value=''
    uploadId = 0
    uploadImgCode1=0
    uploadImgCode2=0
   
}
</script>
<style scoped lang="less">
.disable1 {
    ::v-deep .el-upload--picture-card {
        display: none !important;
    }
}
.disable2 {
    ::v-deep .el-upload--picture-card {
        display: none !important;
    }
}
</style>