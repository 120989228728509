<template>
    <el-form :model="form" label-width="120px" :rules="rules" ref="ruleFormRef">
        <el-form-item label="账号" style="width: 300px;">
            <el-input v-model="form.account" disabled />
        </el-form-item>
        <el-form-item label="密码" style="width: 300px;" prop="password">
            <el-input v-model="form.password" type="password" show-password  placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item label="确认密码" style="width: 300px;" prop="password1">
            <el-input v-model="form.password1" type="password" show-password  placeholder="请再次输入密码"/>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm(ruleFormRef)">修改</el-button>
        </el-form-item>
    </el-form>
</template>
<script  setup>
import { FormInstance, FormRules , ElMessage, ElMessageBox} from 'element-plus'
import { reactive, ref } from 'vue'
import request from '@/api/http'
const ruleFormRef = ref()
// do not use same name with ref
const form = reactive({
    account: window.sessionStorage.UserInfo,
    password: '',
    password1:''
})
const password1 = (rule, value, callback) => {
    console.log(value,form.password)
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== form.password) {
    callback(new Error("两次密码输入不一致"))
  } else {
    callback()
  }
}
const rules = reactive({
    password: [
        { required: true, message: '请填写密码', trigger: 'blur' },
        { min: 5, max: 10, message: '密码长度5-10位', trigger: 'blur' },
    ],
    password1: [
    { required: true, message: '请再次输入密码', trigger: 'blur' },
        { validator:password1, trigger: 'blur' },
       
    ]
})
const submitForm = async (formEl) => {
    if (!formEl) return
    await formEl.validate((valid, fields) => {
        if (valid) {
            request.post('/users/ChangePassword', {
                account: form.account,
                password:form.password
            })
                .then((res) => {
                    if (res.data.code == 200) {
                        ElMessage({
                            message: res.data.msg,
                            type: 'success',
                        })
                        
                    }else{
                        ElMessage.error(res.data.msg)
                    }
                }).catch((res) => {
                    console.log(res)
                });
        } else {
            console.log('error submit!', fields)
        }
    })
}
</script>
  